.flash-cards {
  display: flex;
  justify-content: center;
  position: relative;
  .ant-carousel {
    width: 660px;
    .slick-list {
      .card-wrapper {
        .card {
          padding: 30px;
          height: fit-content;
          height: 660px;
          width: 660px;
          .ant-image-img {
            width: 600px;
          }
        }
      }
    }
    .slick-dots {
      bottom: -40px;
    }
    .slick-dots li button {
      background: #b5cfff;
    }
    .slick-dots li.slick-active button {
      background: #4385ff;
    }
  }
  .arrow {
    cursor: pointer;
    position: absolute;
    height: 16px !important;
    width: 29px !important;
    &.arrow-next {
      z-index: 100;
      transform: rotate(270deg);
      right: 15%;
      bottom: 50%;
      path {
        fill: #4385ff;
      }
    }
    &.arrow-prev {
      transform: rotate(90deg);
      left: 15%;
      bottom: 50%;
      path {
        fill: #4385ff;
      }
    }
  }
}
