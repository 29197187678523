.app {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background: #f9fafc;
  max-width: 1600px;
  margin: 0 auto;
  .coming-soon-section-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    span {
      margin: 1em 0;
      color: #333;
      font-size: 16px;
    }
    h4 {
      font-size: 24px;
    }
  }
  .ant-layout-sider {
    background: #ffffff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 12px;
    .logo {
      padding: 20px;
      margin-top: 6px;
    }
    .ant-menu-item {
      margin: 16px 0;
      display: flex;
      align-items: center;
      .anticon {
        font-size: 20px;
        color: #aaa;
      }
      &:nth-child(2),
      &:nth-child(3) {
        .anticon {
          font-size: 20px;
          color: #aaa;
        }
      }
    }
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border: none;
    }
    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover,
    .ant-menu-item-selected a,
    .ant-menu-item-selected,
    .ant-menu-item-active a,
    .ant-menu-item-active {
      color: #1890ff;
      svg {
        fill: #1890ff;
      }
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
    }
    .ant-menu-item::after {
      right: unset;
      left: 0;
      border-right-color: #1890ff;
    }
    .ant-menu-item:active {
      background-color: #fef8f4;
    }
    .ant-menu-item-selected::after {
      opacity: 1;
      transform: scaleY(1);
    }
    .ant-layout-sider-trigger {
      position: relative;
      background: #ddd;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      span svg {
        color: #333;
      }
    }
    .ant-layout-sider-trigger:hover {
      background: #fef8f4;
    }
  }
  .ant-layout {
    background: #f9fafc;
    .ant-layout-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      margin-top: 3rem;
      padding: 0 2rem;
      h2 {
        display: inline;
        color: #24242c;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-transform: uppercase;
        margin: 0;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: space-around;
        // width: 552px;
        span {
          cursor: pointer;
        }
        .anticon.anticon-info-circle {
          font-size: 24px;
          margin-right: 24px;
          cursor: pointer;
          color: #4385ff;
        }
        .ant-input,
        .ant-input-affix-wrapper {
          background: #f2f4f5;
        }
        .ant-input-affix-wrapper {
          border-radius: 10px;
          border: none;
          border-color: #f68632;
          font-family: var(--font-family-header);
          height: 48px;
          width: 448px;
          box-sizing: border-box;
        }
        .ant-input::placeholder,
        .anticon.anticon-search {
          color: #d0d0d0;
        }
        .ant-input:focus {
          background: white;
        }
        .input.ant-input:focus,
        .ant-input-affix-wrapper-focused {
          border: 1px solid #f68632;
          box-sizing: border-box;
          background: white;
          border-radius: 10px;
          box-shadow: none;
        }
      }
    }
    .ant-input-affix-wrapper {
      width: inherit;
    }
    .ant-layout-content {
      margin-top: 2.5rem;
      padding-bottom: 4rem;
      min-height: 280px;
      & > div {
        max-width: 95%;
        margin: 0 2rem;
      }
    }
  }
}
.navInfo {
  .ant-modal-close-x {
    font-size: 24px;
  }
}
.ant-modal .second-background {
  .green-oval,
  .blue-oval,
  .yellow-oval {
    display: none;
  }
  .main-wrapper {
    z-index: 9;
  }
}
