.logo {
  display: flex;
  align-items: center;
  svg {
    height: 72px;
    width: 120px;
  }
  &.big svg {
    height: 80px;
    width: 120px;
  }
  .logo-image {
    height: 80px;
    image-rendering: -moz-crisp-edges;
  }
}
