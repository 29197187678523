.main-wrapper.auth {
  position: relative;
  z-index: 100;
  background: transparent;

  .ant-layout-header {
    background: transparent;
    padding-top: 2rem;
    height: 72px;
  }

  .auth-logo-wrapper {
    max-width: 80vw;
  }
}

.first-background {
  width: 100%;
  height: 100%;
  background-color: white;

  .yellow-oval {
    position: fixed;
    width: 544px;
    height: 272px;
    right: 0;
    bottom: 0;
    background: #ffcf2a;
    mix-blend-mode: normal;
    opacity: 0.1;
    z-index: 1;
    border-radius: 999px 999px 0 0;
  }
  .green-oval {
    position: fixed;
    width: 138px;
    height: 138px;
    right: 150px;
    top: 6px;

    background: #00af90;
    mix-blend-mode: normal;
    opacity: 0.1;
    z-index: 1;
    border-radius: 50%;
  }
  .blue-oval {
    position: fixed;
    width: 425px;
    height: 425px;
    left: -187px;
    top: -179px;

    background: #f4fdfb;
    z-index: 1;
    border-radius: 50%;
  }
}

.second-background {
  width: 100%;
  background-color: white;

  .yellow-oval {
    position: fixed;
    width: 602px;
    height: 602px;
    left: -113px;
    bottom: -133px;
    background: #ffcf2a;
    mix-blend-mode: normal;
    opacity: 0.1;
    z-index: 1;
    border-radius: 999px;
  }
  .green-oval {
    position: fixed;
    width: 62px;
    height: 62px;
    right: 150px;
    top: 66px;

    background: #f4fdfb;
    z-index: 1;
    border-radius: 50%;
  }
  .blue-oval {
    position: fixed;
    width: 213px;
    height: 425px;
    right: 0px;
    top: 381px;

    background: #00af90;
    mix-blend-mode: normal;
    opacity: 0.1;
    z-index: 1;
    border-radius: 999px 0 0 999px;
  }
}

.third-background {
  width: 100%;
  background-color: white;

  .yellow-oval {
    position: fixed;
    width: 137px;
    height: 137px;
    right: 141px;
    top: 73px;
    background: #ffcf2a;
    mix-blend-mode: normal;
    opacity: 0.1;
    z-index: 1;
    border-radius: 999px;
  }
  .green-oval {
    position: fixed;
    width: 857px;
    height: 857px;
    left: -428px;
    bottom: -108px;

    background: #f4fdfb;
    z-index: 1;
    border-radius: 50%;
  }
  .blue-oval {
    position: fixed;
    width: 62px;
    height: 62px;
    right: 249px;
    bottom: 206px;

    background: #00af90;
    mix-blend-mode: normal;
    opacity: 0.1;
    z-index: 1;
    border-radius: 999px;
  }
}
