@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&family=Poppins:wght@700&family=Crimson+Pro:wght@500&family=Poller+One&display=swap');

@font-face {
  font-family: 'DINNext';
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url('../assets/fonts/DINNextRoundedLTPro-Regular.otf');
}

@font-face {
  font-family: 'DINNext';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../assets/fonts/DINNextRoundedLTPro-Medium.otf');
}

@font-face {
  font-family: 'DINNext';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url('../assets/fonts/DINNextRoundedLTPro-Bold.otf');
}
