.main-wrapper {
    position: relative;
    z-index: 100;
    background: transparent;

    .ant-layout-header {
        background: transparent;
        padding: 7px 0;
        height: 72px;
    }
}

.third-background {
    width: 100%;
    background-color: white;

    .yellow-oval {
        position: fixed;
        width: 137px;
        height: 137px;
        right: 141px;
        top: 73px;
        background: #ffcf2a;
        mix-blend-mode: normal;
        opacity: 0.1;
        z-index: 1;
        border-radius: 999px;
    }
    .green-oval {
        position: fixed;
        width: 857px;
        height: 857px;
        left: -428px;
        bottom: -108px;

        background: #f4fdfb;
        z-index: 1;
        border-radius: 50%;
    }
    .blue-oval {
        position: fixed;
        width: 62px;
        height: 62px;
        right: 249px;
        bottom: 206px;

        background: #00af90;
        mix-blend-mode: normal;
        opacity: 0.1;
        z-index: 1;
        border-radius: 999px;
    }
}

.select-background {
    width: 100%;

    .yellow-oval {
        position: fixed;
        width: 683px;
        height: 683px;
        left: 529px;
        top: 436px;

        background: rgba(255, 207, 42, 0.5);
        mix-blend-mode: normal;
        opacity: 0.1;
        border-radius: 50%;
    }
    .green-oval {
        position: fixed;
        width: 425px;
        height: 425px;
        left: 295px;
        top: -76px;

        background: #e4f9f4;
        opacity: 0.5;
        border-radius: 50%;
    }
    .blue-oval {
        position: fixed;
        width: 138px;
        height: 138px;
        left: 1354px;
        top: 424px;

        background: #e3d870;
        mix-blend-mode: normal;
        opacity: 0.1;
        border-radius: 50%;
    }
}
