$fontSize: 16px;
$numberOfElements: 4;

.intro-slider-text {
  .intro-carousel {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-size: $fontSize;
    line-height: $fontSize * 1.5;
    height: $fontSize * 1.5;

    .carousel-pre {
      height: $fontSize * 1.5;
    }

    .carousel-outer {
      text-align: left;
      height: $fontSize * 1.5;
      overflow: hidden;
      margin-left: $fontSize * 0.25;
      .carousel-inner {
        position: relative;
        animation: rotate 8s ease-in-out infinite;
      }

      .carousel-element {
        display: block;
        font-weight: 700;
      }
    }
  }

  @keyframes rotate {
    0%,
    20% {
      transform: translateY(0);
    }
    25%,
    45% {
      transform: translateY($fontSize * -1.5);
    }
    50%,
    70% {
      transform: translateY($fontSize * -3);
    }
    75%,
    95% {
      transform: translateY($fontSize * -4.5);
    }
    100% {
      transform: translateY($fontSize * -6);
    }
  }
}
