$breakpoints-min: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px,
);

$breakpoints-max: (
  xs: 575px,
  sm: 767px,
  md: 991px,
  lg: 1199px,
  xl: 1599px,
  xxl: 1919px,
  xxxl: 2999px,
);

@mixin for-media($keys...) {
  @each $key in $keys {
    @media (min-width: map-get($breakpoints-min, $key)) and (max-width: map-get($breakpoints-max, $key)) {
      @content;
    }
  }
}
