@import '~antd/dist/antd.css';
@import './styles/global.scss';

select:focus,
input:focus,
button:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-family-header);
}
body {
  margin: 0;
  background: #f9fafc;
  font-family: var(--font-family-body);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .ant-btn-round.ant-btn-lg,
  .ant-btn-lg {
    height: 62px;
    font-weight: 500;
    font-size: 18px;
  }

  .dark-text {
    color: #4f4f4f;
  }
  .alert-danger {
    color: #ffa100;
    .close {
      color: #283955;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  overflow: auto;
}

#mobile-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  .welcome-message {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  .mobile-message {
    font-size: 1.2rem;
    padding: 0 1em;
    text-align: center;
  }
  .store-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
    a {
      text-align: center;
    }
    .app-store {
      width: 40%;
      margin: 1rem 0;
    }
    .play-store {
      width: 40%;
      margin-bottom: 2rem;
    }
  }
}
