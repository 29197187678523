// @import '~normalize.css';
@import 'fonts';
@import 'media-queries';
@import 'nice-scrollbar';

:root {
  --base-font-size: '14px';
  --font-family-header: 'Rubik', serif;
  --font-family-body: 'DINNext', sans-serif;
  --font-color-header: rgba(34, 34, 34, 1);
  --font-color-body: rgba(34, 34, 34, 0.5);
  --font-color-security-message: rgba(255, 160, 61, 1);
  --font-color-warning: rgba(255, 100, 72, 1);
  --bgcol-button-approve: rgba(38, 194, 129, 1);
  --bgcol-button-deny: var(--font-color-warning);
  --bgcol-checkmark-checked: var(--bgcol-button-approve);
  --bgcol-body: rgba(249, 250, 251, 1);
  --bgcol-approval-card: rgba(255, 255, 255, 1);
  --border-color: rgba(34, 34, 34, 0.05);
  --border-style: 1px solid var(--border-color);

  --button-color: rgb(0, 184, 122);
  --button-color-hover: hsl(160, 57%, 55%);

  @include for-media(xs, sm, md, lg) {
    --font-size-header: 16px;
    --font-size-primary: 14px;
    --font-size-secondary: 12px;
  }

  @include for-media(xl, xxl) {
    --font-size-header: 18px;
    --font-size-primary: 16px;
    --font-size-secondary: 14px;
  }

  @include for-media(xxxl) {
    --font-size-header: 20px;
    --font-size-primary: 16px;
    --font-size-secondary: 16px;
  }
}

body {
  background-color: var(--bgcol-body);
  font-family: var(--font-family-body);
  font-size: var(--font-size-primary);
}
