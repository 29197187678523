.preloader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .loader {
        position: relative;
        text-align: center;
        .logo {
            margin-bottom: 16px;
        }
        .logo svg {
            height: 64px;
            width: 64px;     
        }
        .anticon svg {
            height: 36px;
            width: 36px;
        }
    }
}

.preloader-large {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .loader {
        position: relative;
        text-align: center;
        .logo {
            margin-bottom: 20px;
        }
        .logo svg {
            height: 100px;
            width: 100px;     
        }
        .anticon svg {
            height: 60px;
            width: 60px;
        }
    }
}