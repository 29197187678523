@import 'styles/media-queries';

.profile-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .input-container {
    position: relative;
    margin-bottom: 20px;
    p {
      margin-bottom: 4px;
      font-size: 1.1em;
      font-weight: 500;
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 14px;
      color: #5a5a5a;
      display: flex;
      align-items: center;
      opacity: 1;
    }

    .ant-select .ant-select-selector {
      background: #f9fafb;
      border: 1px solid #f9fafb;
      border-radius: 10px;
      width: 333px;
      height: 63px;
      font-family: var(--font-family-header);
      box-sizing: border-box;
      color: #d1d4da;
    }
    .ant-select-focused .ant-select-selection-item,
    .ant-select-focused .ant-select-selection-placeholder,
    .ant-input:focus::placeholder {
      color: #283955;
    }
    .ant-select-focused,
    .ant-input:focus {
      background: #ffffff;
      // border: 1px solid #f68632;
      box-sizing: border-box;
      border-radius: 10px;
      // font-weight: 700;
    }
    .ant-select-focused.ant-select .ant-select-selector {
      background: #ffffff;
    }
    .ant-input {
      background: #f9fafb;
      border: 1px solid #f9fafb;
      border-radius: 10px;
      width: 333px;
      height: 63px;
      border: none;
      font-family: var(--font-family-header);
      box-sizing: border-box;
    }
    .ant-input::placeholder {
      color: #d1d4da;
    }
    .error-message {
      position: absolute;
      bottom: -24px;
      right: 0;
      color: rgb(160 57 69 / 70%);
    }
  }
  .other {
    display: flex;
    flex-direction: column;
    .text {
      font-size: 16px;
      line-height: 70px;
      color: #99a0ac;
      opacity: 0;
      pointer-events: none;
    }
  }
  .description {
    .text {
      font-size: 12px;
      color: #99a0ac;
    }
  }
}
